/*
	<script src="{% static "_assets/js/vendors/jquery-validate/jquery.validate.min.js" %}"></script>
    <script src="{% static "_assets/js/vendors/flickity/flickity.pkgd.min.js" %}"></script>
    <script src="{% static "_assets/js/vendors/Magnific-Popup-master/jquery.magnific-popup.min.js" %}"></script>
    <script src="{% static "_assets/js/vendors/greensock-js/src/minified/TweenMax.min.js" %}"></script>
    <script src="{% static "_assets/js/build/utils.min.js" %}"></script>
    <script type="text/javascript" src="{% static '_assets/js/build/form_validation_handlers.min.js' %}"></script>
    <script type="text/javascript" src="{% static '_assets/js/build/form_validation_handlers.min.js' %}"></script>
    <script type="text/javascript" src="{% static '_assets/js/build/login_handlers.min.js' %}"></script>
*/

console.log('base_vendors');
import '@base_js/modules/jquery.module.js'
import validate from '@node_modules/jquery-validation'
import Flickity from '@node_modules/flickity'
import jQueryBridget from '@node_modules/jquery-bridget/jquery-bridget'
jQueryBridget( 'flickity', Flickity, $ );

import '@node_modules/magnific-popup'
import '@node_modules/jquery-match-height'
import '@custom_vendors/jquery-autocomplete/src/jquery.autocomplete'
import { TweenMax } from "@node_modules/gsap/TweenMax";
import ScrollToPlugin from "@node_modules/gsap/ScrollToPlugin";

import '@base_js/form_validation_handlers'
import '@base_js/login_handlers'

