import {
  beforePophover,
  afterPophover,
  minHeightFix,
  userMinHeightFix,
  createBirthDateSelect,
  deparam
} from '@base_js/utils'

(function($){
    jQuery(function($){
        var header = $('#new-hf-header'),
            d = $(document);

        $('.new-hf-language-selector').each(function()
        {
            var box = $(this);
            box.find('.new-hf-current').on('click', function()
            {
                box.toggleClass('new-hf-language-selector-open');
                if (box.hasClass('new-hf-language-selector-open')) d.on('click.language', function(e)
                {
                    var t = $(e.target);
                    if (!t.is(box) && !t.closest('.new-hf-language-selector-open').is(box))
                    {
                        box.removeClass('new-hf-language-selector-open');
                        d.off('click.language');
                    }
                });
                return false;
            });
        });

        header.find('.new-hf-menu-toggle').on('click', function()
          {
              header.toggleClass('new-hf-open');
              return false;
          });

            header.find('.new-hf-account-toggle').on('click', function()
          {
              var box = $(this).parent();
              box.toggleClass('new-hf-account-open');

              if (box.hasClass('new-hf-account-open')) d.on('click.account', function(e)
              {
                  var t = $(e.target);
                  console.log(t.is(box), t.closest('.new-hf-account-open').is(box));
                  if (!t.is(box) && !t.closest('.new-hf-account-open').is(box))
                  {
                      box.removeClass('new-hf-account-open');
                      d.off('click.account');
                  }
              });
              return false;
          });

        var loginPopup = $('#login-popup');

        if (loginPopup.length)
        {
            var showLoginPopupPanel = function(activeSel)
            {
                loginPopup.find('> .new-hf-panels').removeClass('new-hf-panel-active');
                loginPopup.find(activeSel).addClass('new-hf-panel-active');
            };

            loginPopup.find('.new-hf-switch-tab-link').on('click', function()
            {
                showLoginPopupPanel($(this).attr('href'));
                return false;
            });

            var loginForm = loginPopup.find('.new-hf-login-form'),
                recoveryForm = loginPopup.find('.new-hf-password-recovery-form');

            loginForm.on('ajax-submit', function()
            {
                var msg = loginForm.find('.new-hf-msg-error');
                msg.addClass('new-hf-hidden').text('');

                $.ajax(
                    {
                        data: loginForm.serialize(),
                        method: 'POST',
                        url: loginForm.attr('action')
                    }).done(function(data)
                {
                    console.log("done", arguments);
                    if (data.success){
                        if(!!$('#login-popup').data('next')){
                            console.log('WORKAROUND');
                            location.href = $('#login-popup').data('next');
                        }else if(data.redirect_to && window.allow_redirect){
                            location.href = data.redirect_to;
                        }else{
                            location.reload();
                        }
                    }
                    else
                    {
                        loginForm.find('.new-hf-input-text').addClass('new-hf-error');
                        msg.text(data.msg ? data.msg : msg.data('default')).removeClass('new-hf-hidden');
                    }
                }).fail(function()
                {
                    console.log("fail", arguments);

                    loginForm.find('.new-hf-input-text').addClass('new-hf-error');
                    msg.text(msg.data('default')).removeClass('new-hf-hidden');
                }).always(function(a, textStatus, b)
                {
                    console.log("always", arguments);
                });
                return false;
            });

            recoveryForm.on('ajax-submit', function()
            {
                var msg = recoveryForm.find('.new-hf-msg-error');
                msg.addClass('new-hf-hidden').text('');

                $.ajax(
                    {
                        data: recoveryForm.serialize(),
                        method: 'POST',
                        url: recoveryForm.attr('action')
                    }).done(function(data)
                {
                    if (data.success) showLoginPopupPanel('#new-hf-login-popup-recovery-ok');
                    else
                    {
                        recoveryForm.find('.new-hf-input-text').addClass('new-hf-error');
                        if (data.response === 'no user') {
                            msg.text(data.msg ? data.msg : msg.data('neuser')).removeClass('new-hf-hidden');
                        } else {
                            msg.text(data.msg ? data.msg : msg.data('default')).removeClass('new-hf-hidden');
                        }
                    }
                }).fail(function()
                {
                    recoveryForm.find('.new-hf-input-text').addClass('new-hf-error');
                    msg.text(msg.data('default')).removeClass('new-hf-hidden');
                });
                return false;
            });

            if(!$('.mobileDevice').length || $(window).width() > 767 ){
                $('.js-open-login-popup').each(function(i,el){
                    var $el = $(el);
                    $el.on('click', function(e){
                        e.preventDefault();
                        var opts = {
                            type:'inline',
                            removalDelay: 300,
                            mainClass: 'my-mfp-zoom-in',
                            midClick: true,
                            closeMarkup: '<button type="button" class="mfp-close new-hf-icon new-hf-icon-close-l"></button>',
                            callbacks: {
                                open: function(item)
                                {
                                    if($el.data('next')){
                                        $('.js-next-url').val($el.data('next'));
                                    }
                                    if( $el.data('step') === 'register'){
                                        showLoginPopupPanel('#new-hf-login-popup-register');
                                    }else{
                                        showLoginPopupPanel('#new-hf-login-popup-login');
                                    }

                                    if(!$el.data('step') || !$el.data('next')){
                                        var $next = $('[name=next]');
                                        $next.val($next.val() + window.location.search);
                                    }
                                },
                                beforeOpen: function () {
                                    beforePophover();
                                },
                                afterClose: function () {
                                    afterPophover();
                                }
                            }
                        };
                        if(!$el.data('condition') || (!!$el.data('condition'))){
                            $el.magnificPopup(opts).magnificPopup('open');
                        }
                    });
                }) 
            }else{
                $('.js-open-login-popup').on('click', function(e){
                    $el = $(e.currentTarget);
                    if(!!$el.data('next')){
                        $('#login-popup').data('next', $el.data('next')); 
                    }
                    if(!$el.data('condition') || (!!$el.data('condition'))){
                        $('#login-popup > div').each(function(i, element){
                            $(element).removeClass('new-hf-panel-active');
                        });
                        if( $el.data('step') === 'register'){
                            $('#new-hf-login-popup-register').addClass('new-hf-panel-active');
                        }else{
                            $('#new-hf-login-popup-login').addClass('new-hf-panel-active');
                        }
                        beforePophover();
                        TweenMax.fromTo($("#login-popup"), 0.5, {autoAlpha: 0, right: '-100%'}, { autoAlpha: 1, right: 0});
                    }
                });
                $('.js-mobileCloseButton').on('click', function(e){
                    e.preventDefault();
                    TweenMax.fromTo($("#login-popup"), 0.5, {autoAlpha: 1, right: '0'}, { autoAlpha: 0, right: '-100%'});
                    afterPophover();
                });
            }
        }    
    });
})(jQuery)