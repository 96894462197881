console.log('base');

import '@base_js/base_vendors.js'

import {
  beforePophover,
  afterPophover,
  minHeightFix,
  userMinHeightFix,
  createBirthDateSelect,
  deparam
} from '@base_js/utils'

$(function() {
  var calculateDistance, upt;
  if (typeof $(".jstextoverflow").textOverflow === 'function') {
    $(".jstextoverflow").textOverflow({
      str: "...",
      autoUpdate: true
    });
  }
  calculateDistance = function(elem, mouseX, mouseY) {
    return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left + (elem.width() / 2)), 2) + Math.pow(mouseY - (elem.offset().top + (elem.height() / 2)), 2)));
  };
  upt = $("#userPopoverToggle");
  upt.click(function(evt) {
    evt.preventDefault();
    return false;
  });
  if ($.fn.popover && upt) {
    return upt.popover({
      container: 'body',
      placement: 'bottom',
      html: true,
      template: '<div class="popover user-popover-menu" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
      content: function() {
        return $("#userPopoverMenu").html();
      }
    });
  }
});

function checkScrollPositionForCookies() {
	console.log('checkScrollPositionForCookies')
    if($(window).scrollTop() > 100) {
        $(window).off("scroll", checkScrollPositionForCookies);
        PdbCookielaw.createCookielawCookieWithoutClosing();
    }
}

$(window).on('scroll', checkScrollPositionForCookies);

$(document).ready(function() {
    var $cb = $("#cookie_banner");

    if($cb) {
        setTimeout(function() {
            $cb.slideDown();
        }, 500);
    }
});

$(document).on('keyup keypress', 'form input[type="text"]', function(e) {
  if(e.which == 13 && e.currentTarget.attributes.id.value != 'project-search-input') {
  	console.log('project-search-input')
    e.preventDefault();
    return false;
  }
});