console.log('form_validation_handlers');

jQuery(function($){
    var d = $(document);
    $('.js-std-form-validation').each(function()
    {
        var form = $(this),
            showErrors = form.data('visible-errors');

        var options = {
            ignore: [],
            errorElement: 'em'/*,
             onkeyup: false,
             onclick: false*/
        };
        if (showErrors)
        {
            options.errorPlacement = function(error, element)
            {
                error.appendTo(element.parent());
            }
        }
        else
        {
            $('<div class="new-hf-form-errors new-hf-hidden"></div>').prependTo(form);
            options.errorLabelContainer = form.find('.new-hf-form-errors');
        }
        if (form.hasClass('new-hf-ajax-form')) options.submitHandler = function()
        {
            form.trigger('ajax-submit');
            return false;
        };
        console.log('js-std-form-validation validate call')
        form.validate(options);
    });

    var profileHeaderSlider = $('#new-hf-profile-header-slider');
    if (profileHeaderSlider.length)
    {
        var activeProfileHeaderSlider = profileHeaderSlider.find('a.active').parent();
        profileHeaderSlider.flickity(
        {
            accessibility: false,
            cellAlign: 'left',
            contain: true,
            freeScroll: true,
            prevNextButtons: false,
            pageDots: false,
            initialIndex: activeProfileHeaderSlider.length ? activeProfileHeaderSlider.index() : 0
        });
    }
    $('.js-completeProfileForm').on('submit', function(e){
        var form = $(e.currentTarget),
            ret = true;
        $('.form-control', form).each(function(i, v){
            var field = $(v),
                formGroup = field.parents('.form-group'),
                val = field.val();
            formGroup.removeClass('has-error');
            $('.help-block', formGroup).addClass('hidden');
            if(val.length < 3){
                ret = false;
                formGroup.addClass('has-error');
                $('.help-block', formGroup).removeClass('hidden');
            }
        });

        return ret;
    });
});
