console.log('utils');

import moment from '@node_modules/moment';

/*! https://mths.be/startswith v0.2.0 by @mathias */
if (!String.prototype.startsWith) {
  console.log('String.startsWith polyfill')
  (function() {
    'use strict'; // needed to support `apply`/`call` with `undefined`/`null`
    var defineProperty = (function() {
      // IE 8 only supports `Object.defineProperty` on DOM elements
      try {
        var object = {};
        var $defineProperty = Object.defineProperty;
        var result = $defineProperty(object, object, object) && $defineProperty;
      } catch(error) {}
      return result;
    }());
    var toString = {}.toString;
    var startsWith = function(search) {
      if (this == null) {
        throw TypeError();
      }
      var string = String(this);
      if (search && toString.call(search) == '[object RegExp]') {
        throw TypeError();
      }
      var stringLength = string.length;
      var searchString = String(search);
      var searchLength = searchString.length;
      var position = arguments.length > 1 ? arguments[1] : undefined;
      // `ToInteger`
      var pos = position ? Number(position) : 0;
      if (pos != pos) { // better `isNaN`
        pos = 0;
      }
      var start = Math.min(Math.max(pos, 0), stringLength);
      // Avoid the `indexOf` call if no match is possible
      if (searchLength + start > stringLength) {
        return false;
      }
      var index = -1;
      while (++index < searchLength) {
        if (string.charCodeAt(start + index) != searchString.charCodeAt(index)) {
          return false;
        }
      }
      return true;
    };
    if (defineProperty) {
      defineProperty(String.prototype, 'startsWith', {
        'value': startsWith,
        'configurable': true,
        'writable': true
      });
    } else {
      String.prototype.startsWith = startsWith;
    }
  }());
}
function beforePophover(){
    var scrollPosition = [
      self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
      self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
    ];

    var html = $('html'); // it would make more sense to apply this to body, but IE7 won't have that
    html.data('scroll-position', scrollPosition);
    html.data('previous-overflow', html.css('overflow'));
    window.scrollTo(scrollPosition[0], scrollPosition[1]);
    $('html, body, .mfp-bg').css({
        overflow: 'hidden'
    });
}

function afterPophover(){
    var html = $('html');
    var scrollPosition = html.data('scroll-position');
    $('html, body, .mfp-bg').css('overflow', html.data('previous-overflow'));
    window.scrollTo(scrollPosition[0], scrollPosition[1])
}

function minHeightFix(){
    var footer = $('#footer'),
        content = $('.minHeightFix'),
        v = $(window).height() - (footer.outerHeight() + parseInt(footer.css('margin-top'))) - parseInt(content.css('padding-top'));
    content.css({ 'min-height': v+'px' });
}

function userMinHeightFix(){
    var footer = $('#footer'),
        content = $('.minHeightFix'),
        v = $(window).height() -
            parseInt(content.css('padding-bottom')) +
            parseInt($('.profile--content', content).css('margin-top')) -
            ($("#new-hf-header").outerHeight() + parseInt($("#new-hf-header").css('margin-top'))) -
            ($("#new-hf-footer").outerHeight() + parseInt($("#new-hf-footer").css('margin-top')));
    content.css({ 'min-height': v+'px' });
}

function createBirthDateSelect($el, format, $form){
    console.log("createBirthDateSelect",$el.val());
    if(!$form){
      $form = $el.parents('form')
    }
    $el.addClass('hidden');
    $el.data('bk', $el.val());
    $el.data('temp', $el.val());
    var wrapper = $('<div class="selectWrapper selectDateWrapper"></div>'),
        stringDate = !!$el.val() ? $el.val() : null,
        date = !!stringDate ? moment(stringDate, !!format ? format : 'YYYY-MM-DD' ) : moment();

    var daySelectWrapper = $('<span class="std-select"><select class="customDateSelect" name="day"></select></span>');
    
    $('select', daySelectWrapper).append($('<option>', {
      text: $el.data('dayplaceholder'),
      disabled: true,
      selected: !!$el.val() ? false : true
    }));

    for (var i = 0; i<31;i++){
        $('select', daySelectWrapper).append($('<option>', {
            value: i+1,
            text: i+1
        }));
    }

    moment.locale($('html').attr('lang'));

    var monthSelectWrapper = $('<span class="std-select"><select class="customDateSelect" name="month"></select></span>'),
        months = moment.months();

    $('select', monthSelectWrapper).append($('<option>', {
      text: $el.data('monthplaceholder'),
      disabled: true,
      selected: !!$el.val() ? false : true
    }));

    for(var i=0; i<months.length;i++){
        var s = months[i];
        $('select', monthSelectWrapper).append($('<option>', {
            value: moment().month(s).format('MM'),
            text: s.charAt(0).toUpperCase() + s.slice(1)
        }));
    }

    var yearSelectWrapper = $('<span class="std-select"><select class="customDateSelect" name="year"></select></span>');
    $('select', yearSelectWrapper).append($('<option>', {
      text: $el.data('yearplaceholder'),
      disabled: true,
      selected: !!$el.val() ? false : true
    }));

    for(var i=2017; i>1920;i--){
        var s = months[i];
        $('select', yearSelectWrapper).append($('<option>', {
            value: i,
            text: i
        }));
    }

    wrapper.append(daySelectWrapper);
    wrapper.append(monthSelectWrapper);
    wrapper.append(yearSelectWrapper);

    $el.parent().append(wrapper);

    var daySelect   = $('[name=day]', wrapper),
        monthSelect = $('[name=month]', wrapper),
        yearSelect  = $('[name=year]', wrapper);
    /*
    console.log('---> ', $el.val());
    console.log('---> ', date);
    */
    if($el.val()){
        daySelect.val(date.format('D'));
        monthSelect.val(date.format('MM'));
        yearSelect.val(date.format('YYYY'));
    }

    
    /*
    console.log('createBirthDateSelect validate')
    $el.parents('form').validate().element("[name=day]");
    $el.parents('form').validate().element("[name=month]");
    $el.parents('form').validate().element("[name=year]");
    */
    $form.validate().element("[name=day]");
    $form.validate().element("[name=month]");
    $form.validate().element("[name=year]");

    wrapper.on('change keyup keydown', '[name=day]', function(e){
      $form.validate().element("[name=day]");
        buildDate();
    });

    wrapper.on('change keyup keydown', '[name=month]', function(e){
      $form.validate().element("[name=month]");
        buildDate();
    });

    wrapper.on('change keyup keydown', '[name=year]', function(e){
      $form.validate().element("[name=year]");
        buildDate();
    });

    var buildDate = function(){
        var day   = parseInt($('[name=day] option:selected').val()),
            month = parseInt($('[name=month] option:selected').val()),
            year  = parseInt($('[name=year] option:selected').val()),
            s = year + '/' + month + '/'+ day,
            d;
        if(!!day && !!month && !!year){
            d = moment(s);
            /*
            console.log(day, month, year);
            console.log(s, d.format('DD/MM/YYYY'), d.isValid());
            */ 
            if(d.isValid()){
              $el.val(d.format('YYYY-MM-DD'));
            }
        }else{
            $el.val('');
        }
    };
}


function deparam(querystring) {
  // remove any preceding url and split
  querystring = querystring.substring(querystring.indexOf('?')+1).split('&');
  var params = {}, pair, d = decodeURIComponent, i;
  // march and parse
  for (i = querystring.length; i > 0;) {
    pair = querystring[--i].split('=');
    params[d(pair[0])] = d(pair[1]);
  }

  return params;
};//--  fn  deparam


$('.sdgWrapper input[type=checkbox]').on('change', function(evt) {
    var limit = 3,
        l = $(this).closest('.sdgWrapper').find(':checked').length;
    console.log('MOVE THIS SNIPPET IN PROJECT DETAIL MODULE')
    if(l > limit) {
      console.log('limit reached')
      this.checked = false;
    }
});

export {
  beforePophover,
  afterPophover,
  minHeightFix,
  userMinHeightFix,
  createBirthDateSelect,
  deparam
}